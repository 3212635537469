import React, { useEffect, useState } from "react";
import config from "./Utils/config";
import { Bar } from "react-chartjs-2";
import Loader from "./Utils/loader";

const ContractGraph = () => {
    const [rawData, setRawData] = useState([]); // Store the raw data
    const [filteredData, setFilteredData] = useState([]); // Store filtered and grouped data
    const [chartData, setChartData] = useState(null);
    const [statusList, setStatusList] = useState([]); // Store unique contract statuses with names
    const [selectedStatus, setSelectedStatus] = useState(null); // Default to null to show all contracts initially
    const token = localStorage.getItem('token');

    useEffect(() => {
        fetchDataFromAPI();
        fetchContractStatuses(); // Fetch contract statuses on component mount
    }, []);

    // Fetch data from API
    const fetchDataFromAPI = async () => {
        try {
            const response = await fetch(config.apiBaseUrl + 'ContractTimeTeamInfoes', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            setRawData(data); // Store raw data
            filterDataByDateAndStatus(data); // Filter the fetched data initially
        } catch (error) {
            console.error("Error fetching data from API:", error);
        }
    };

    // Fetch the contract statuses from the API or hardcoded values
    const fetchContractStatuses = () => {
        const statuses = [
            { contractStatusId: 1, contractStatusName: "Confirmed", contractStatusColor: "00ff00" },
            { contractStatusId: 2, contractStatusName: "Approved", contractStatusColor: "66ccff" },
            { contractStatusId: 3, contractStatusName: "Booked", contractStatusColor: "ffffff" },
            { contractStatusId: 4, contractStatusName: "Quoted", contractStatusColor: "ffff00" },
            // { contractStatusId: 5, contractStatusName: "Cancelled", contractStatusColor: "ff0000" }
        ];
        setStatusList(statuses);
    };

    // Filter data based on contract status and date
    const filterDataByDateAndStatus = (data) => {
        const today = new Date();
        const lastWeek = new Date(today);
        lastWeek.setDate(today.getDate() - 7);
        if (Array.isArray(data)) {
            const filtered = data.filter(item => {
                const entryDate = new Date(item.entryDate);
                const isDateValid = entryDate >= lastWeek && entryDate <= today;
                const isStatusValid = !selectedStatus || item.contractStatusId === selectedStatus;
                return isDateValid && isStatusValid;
            });

            const dailyCounts = groupByDay(filtered);
            setFilteredData(dailyCounts); 
            generateChartData(dailyCounts);
        } else {
            console.error("Expected 'data' to be an array, but got", data);
        }
    };

    // Group the contracts by day of the week
    const groupByDay = (data) => {
        const dayCounts = {
            Monday: 0,
            Tuesday: 0,
            Wednesday: 0,
            Thursday: 0,
            Friday: 0,
            Saturday: 0,
            Sunday: 0
        };

        data.forEach(item => {
            const entryDate = new Date(item.entryDate);
            const dayOfWeek = entryDate.toLocaleString('en-us', { weekday: 'long' });
            if (dayCounts[dayOfWeek] !== undefined) {
                dayCounts[dayOfWeek]++;
            }
        });

        return dayCounts;
    };

    // Generate the chart data
    const generateChartData = (dailyCounts) => {
        const labels = Object.keys(dailyCounts);
        const data = Object.values(dailyCounts);

        setChartData({
            labels: labels,
            datasets: [
                {
                    label: 'Contracts Per Day',
                    data: data,
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                }
            ]
        });
    };

    const configs = {
        type: 'bar',
        options: {
            plugins: {
                title: {
                    display: true,
                    text: 'Contract Information by Day',
                },
            },
            responsive: true,
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                    beginAtZero: true,
                }
            }
        }
    };

    // Handle contract status change
    const handleStatusChange = (event) => {
        const selected = event.target.value ? parseInt(event.target.value, 10) : null;
        setSelectedStatus(selected);

        // Re-filter raw data when status is changed
        if (Array.isArray(rawData)) {
            filterDataByDateAndStatus(rawData);
        }
    };

    // Get the contract status name based on contractStatusId
    const getStatusNameById = (statusId) => {
        const status = statusList.find(status => status.contractStatusId === statusId);
        return status ? status.contractStatusName : "Unknown";
    };

    return (
        <div>
            {/* Check if chartData is available before rendering Bar chart */}
            {chartData ? (
                <div className="dashboard-grid-item-one mt-3">
                    <div className="d-flex justify-content-between">
                        <h3>Contract Information</h3>
                        <div className="status-filter">
                            <select onChange={handleStatusChange} value={selectedStatus || ''}>
                                <option value="">All</option> 
                                {statusList.map(status => (
                                    <option key={status.contractStatusId} value={status.contractStatusId}>
                                        {status.contractStatusName}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="component">
                        <Bar data={chartData} options={configs.options} />
                    </div>
                </div>
            ) : (
                <div>
                    <Loader />
                </div> 
            )}
        </div>
    );
};

export default ContractGraph;
