import React, { useState, useEffect, useContext } from "react";
import { Bar } from "react-chartjs-2"; // Import Bar chart from react-chartjs-2
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import config from "../Utils/config";
import { ApiDataContext } from "./ApiDataContext";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

function UserTotalContract() {
  const { barToday } = useContext(ApiDataContext);
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState("");
  const [error, setError] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem("token");

    // Fetch teams
    const fetchTeams = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await fetch(
          `${config.apiBaseUrl}Users/getCurrentUser-Details`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setData(data);
      } catch (error) {
        setError("Error fetching teams: " + error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchTeams();
  }, []);

  const getContractsByDate = () => {
    if (!barToday || barToday.length === 0) return [];

    const filteredData = barToday.filter(
      (contract) => contract.branchId === data.branchId
    );

    const contractCountByDate = filteredData.reduce((acc, contract) => {
      const date = contract.date;
      if (acc[date]) {
        acc[date] += 1;
      } else {
        acc[date] = 1;
      }
      return acc;
    }, {});

    return contractCountByDate;
  };

  const contractCountByDate = getContractsByDate();
  const chartData = {
    labels: Object.keys(contractCountByDate),
    datasets: [
      {
        label: "Total Contracts by Date",
        data: Object.values(contractCountByDate),
        backgroundColor: "rgba(75, 192, 192, 0.2)",
        borderColor: "rgba(75, 192, 192, 1)",
        borderWidth: 1,
      },
    ],
  };

  // Calculate total number of contracts
  const totalContracts = Object.values(contractCountByDate).reduce(
    (sum, count) => sum + count,
    0
  );

  const chartOptions = {
    responsive: true,
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div>
      {loading && <p>Loading...</p>}
      {error && <p>{error}</p>}

      <h3>Total Contracts by {data.userName}</h3>

      <div style={{ marginBottom: "20px" }}>
        <strong>Total Number of Contracts: {totalContracts}</strong>
      </div>

      <Bar data={chartData} options={chartOptions} />
    </div>
  );
}

export default UserTotalContract;
