import React, { useEffect, useState } from 'react';
import axios from 'axios';
import '../css/Agent.css';
import config from './Utils/config';
import Loader from './Utils/loader';
import showToast from './Utils/showToast';
import ConfirmationModal from './Utils/ConfirmationModal';
import AgentFilters from './Utils/AgentFilters';

const Agent = () => {
  const [agents, setAgents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [originalAgents, setOriginalAgents] = useState([]); // State for original agents
  const [error, setError] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [currentAgent, setCurrentAgent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isRoleModalOpen, setIsRoleModalOpen] = useState(false);
  const [agentToStatus, setAgentToStatus] = useState(null);
  const [statusMessage, setStatusMessage] = useState('');
  const [agentToAdmin, setAgentToAdmin] = useState(null);
  const [adminMessage, setAdminMessage] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordMismatch, setPasswordMismatch] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [isAdding, setIsAdding] = useState(false);
  const [newAgent, setNewAgent] = useState({ username: '', emailAddress: '', password: '', branchId: null,role:''});
  const [newRole, setNewRole] = useState('');
  const [branches, setBranches] = useState([])


  useEffect(() => {
    const fetchAgentList = async () => {
      try {
        const apiUrl = config.apiBaseUrl + 'Dashboard/AgentList';
        const token = localStorage.getItem('token');
        const response = await axios.get(apiUrl, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setAgents(response.data);
        setLoading(false);
        setOriginalAgents(response.data); // Store original agents
      } catch (error) {
        setError(error);
        setLoading(false);
      }
    };

    fetchAgentList();
  }, []);

  useEffect(() => {
    const fetchCompaniesAndBranches = async () => {
      try {
        const token = localStorage.getItem('token');

       

        // Fetch Branches
        const branchResponse = await axios.get(config.apiBaseUrl + 'Branches/BranchList', {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setBranches(branchResponse.data);

      } catch (error) {
        setError(error);
      }
    };

    fetchCompaniesAndBranches();
  }, []);

  const handleSearchDataChange = (search) => {
    const hasSearchValues = Object.values(search).some(value => value !== "");

    if (!hasSearchValues) {
      setAgents(originalAgents); // Reset to original agents if no search values
      return;
    }
    console.log(search);
    console.log(originalAgents);
    setAgents(() => {
      return originalAgents.filter(agent => {
        return (
          (search.userName === "" || agent.username.startsWith(search.userName)) &&
          (search.email === "" || agent.emailAddress.startsWith(search.email)) &&
          (search.branch === "" || agent.branchName.startsWith(search.branch)) &&
          (search.status === "" || String(agent.isActive) === search.status) &&
          (search.createdDateFrom === "" || new Date(agent.createdDate) >= new Date(search.createdDateFrom)) &&
          (search.createdDateTo === "" || new Date(agent.createdDate) <= new Date(search.createdDateTo))
        );
      });
    });
  };

  if (loading) {
    return <Loader isLoading={loading} />;
  }

  if (error) {
    return <div>Error fetching data: {error.message}</div>;
  }



  const handleEdit = (agent) => {
    setCurrentAgent(agent);
    setIsEditing(true);
    setConfirmPassword('');
    setPasswordMismatch(false);
    setShowConfirmPassword(false);
  };

  const handleStatusConfirmation = (agent) => {
    setAgentToStatus(agent.userId);
    setStatusMessage(`Are you sure you want to change the status of "${agent.username}"?`);
    setIsModalOpen(true);
  };
  const handleRoleConfirmation = (agent) => {
    setAgentToAdmin(agent.userId);
    setNewRole(agent.role);
    setIsRoleModalOpen(true);
  };
  const handleAdmin = async () => {
    try {
      const token = localStorage.getItem('token');
      const statusUrl = `${config.apiBaseUrl}Dashboard/UpdateRole/${agentToAdmin}?role=${newRole}`;
      await axios.put(statusUrl, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      // setAgents((prevAgents) =>
      //   prevAgents.map((agent) =>
      //     agent.userId === agentToAdmin ? { ...agent, role: newRole } : agent
      //   )
      // );

      showToast({
        type: 'success',
        message: "Role changed successfully!",
      });
      window.location.reload();
    } catch (error) {
      showToast({
        type: 'error',
        message: "Error changing agent role!",
      });
    } finally {
      setIsRoleModalOpen(false);
      setAgentToAdmin(null);
      setNewRole('');
    }
  };

  const handleStatus = async () => {
    try {
      const token = localStorage.getItem('token');
      const statusUrl = `${config.apiBaseUrl}Dashboard/UpdateStatus/${agentToStatus}`;
      await axios.put(statusUrl, {}, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      setAgents((prevAgents) => prevAgents.map(agent =>
        agent.userId === agentToStatus
          ? { ...agent, isActive: !agent.isActive }
          : agent
      ));
      showToast({
        type: 'success',
        message: "Status changed successfully!",
      });
    } catch (error) {
      showToast({
        type: 'error',
        message: "Error changing agent status!",
      });
    } finally {
      setIsModalOpen(false);
      setAgentToStatus(null);
      setStatusMessage('');
    }
  };

  const handleSave = async (updatedAgent) => {
    if (currentAgent.password !== confirmPassword) {
      setPasswordMismatch(true);
      return;
    }

    try {
      const apiUrl = config.apiBaseUrl + 'Dashboard/AgentUpdate';
      const token = localStorage.getItem('token');

      const agentToUpdate = { ...updatedAgent };

      if (currentAgent.password) {
        agentToUpdate.password = currentAgent.password;
      } else {
        delete agentToUpdate.password;
      }

      await axios.put(`${apiUrl}/${updatedAgent.userId}`, agentToUpdate, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      setAgents((prevAgents) =>
        prevAgents.map((agent) =>
          agent.userId === updatedAgent.userId ? updatedAgent : agent
        )
      );
      showToast({
        type: 'success',
        message: "Updated successfully!",
      });
    } catch (error) {
      showToast({
        type: 'error',
        message: "Not Update!",
      });
    } finally {
      setIsEditing(false);
      setCurrentAgent(null);
      setConfirmPassword('');
      setPasswordMismatch(false);
      setShowConfirmPassword(false);
    }
  };
  const handleAddAgent = async () => {
    try {
      const token = localStorage.getItem('token');
      const response = await axios.post(config.apiBaseUrl + 'Dashboard/AgentCreate', newAgent, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      setAgents((prevAgents) => [...prevAgents, response.data]);
      showToast({
        type: 'success',
        message: "Agent added successfully!",
      });
    } catch (error) {
      showToast({
        type: 'error',
        message: "Error adding agent!",
      });
    } finally {
      setIsAdding(false);
      setNewAgent({ username: '', emailAddress: '', password: '', branchId: null,role:null });
    }
  };

  return (
    <div className="agent-table">
      <div className='d-flex justify-content-between'>
        <h2>Agent List</h2>
        <button className='btn btn-primary' onClick={() => setIsAdding(true)}>+ Add</button>
      </div>
      <AgentFilters onSearchDataChange={handleSearchDataChange} branches={branches} />


      <table border="1" cellPadding="10" cellSpacing="0">
        <thead>
          <tr>
            <th>Username</th>
            <th>EmailAddress</th>
            <th>Branch</th>
            {/* <th>CompanyId</th> */}
            <th>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {agents.map((agent, index) => (
            <tr key={`${agent.UserId}-${index}`}>
              <td>{agent.username}</td>
              <td>{agent.emailAddress}</td>
              <td>{agent.branchName}</td>
              {/* <td>{agent.companyId}</td> */}
              <td>
                <span className={agent.isActive ? 'status-active' : 'status-inactive'}>
                  {agent.isActive ? 'Active' : 'Inactive'}
                </span>
              </td>
              <td>
                <button className="action-button edit-button" onClick={() => handleEdit(agent)} title="Edit Agent">
                  <i className="fa fa-edit"></i>
                </button>
                <button className="action-button delete-button" onClick={() => handleStatusConfirmation(agent)} title="Update Status">
                  <i className="fa fa-sync"></i>
                </button>
                <button className="action-button admin-button" onClick={() => handleRoleConfirmation(agent)} title="Change Role">
                  <i className="fas fa-user-shield"></i>
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <ConfirmationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        onConfirm={handleStatus}
        message={statusMessage}
      />
      {isRoleModalOpen && (
        <div className="popup-overlay">
          <div className="popup">
            <div className='d-flex justify-content-between'>
              <h2>Change Role</h2>
              <span role='button' onClick={() => setIsRoleModalOpen(false)}>&#10007;</span>
            </div>
            <hr />
            <form onSubmit={(e) => { e.preventDefault(); handleAdmin(); }}>
              <label>
                Select Role
                <select
                  value={newRole}
                  onChange={(e) => setNewRole(e.target.value)}
                >
                  <option value="Agent">Agent</option>
                  <option value="Frenchie">Frenchie</option>
                  {/* <option value="Admin">Admin</option> */}
                </select>
              </label>
              <button type="submit">Update Role</button>
              <button type="button" onClick={() => setIsRoleModalOpen(false)}>Cancel</button>
            </form>
          </div>
        </div>
      )}

      {isAdding && (
        <div className="popup-overlay">
          <div className="popup">
            <div className='d-flex justify-content-between'>
              <h2>Add Agent</h2>
              <span role='button' onClick={() => setIsAdding(false)}>&#10007;</span>
            </div>
            <hr />
            <form onSubmit={(e) => { e.preventDefault(); handleAddAgent(); }}>
              <label>
                Username
                <input
                  type="text"
                  value={newAgent.username}
                  onChange={(e) => setNewAgent({ ...newAgent, username: e.target.value })}
                  required
                />
              </label>
              <label>
                Email
                <input
                  type="email"
                  value={newAgent.emailAddress}
                  onChange={(e) => setNewAgent({ ...newAgent, emailAddress: e.target.value })}
                  required
                />
              </label>
              <label>
                Password
                <input
                  type="text"
                  value={newAgent.password}
                  onChange={(e) => {
                    const newPassword = e.target.value;
                    setNewAgent({ ...newAgent, password: newPassword });
                    setShowConfirmPassword(newPassword !== '');
                    if (newPassword === '') setConfirmPassword('');
                  }}
                  required
                />
              </label>
              {/* Only show confirm password field if showConfirmPassword is true */}
              {showConfirmPassword && (
                <label>
                  Confirm Password
                  <input
                    name="confirmPassword"
                    type="text"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </label>
              )}
              {passwordMismatch && <p style={{ color: 'red' }}>Passwords do not match!</p>}
              <label>
                Branch
                
                <select
                  value={newAgent.branchId}
                  onChange={(e) => setNewAgent({ ...newAgent, branchId: e.target.value })}
                  required
                >
                  <option value="">Select Branch</option>
                  {branches.map(branch => (
                    <option key={branch.branchId} value={branch.branchId}>
                      {branch.branchName}
                    </option>
                  ))}
                </select>
              </label>
              <label>
                Role
               
                <select
                  value={newAgent.role}
                  onChange={(e) => setNewAgent({ ...newAgent, role: e.target.value })}
                  required
                >
                  <option value="">Select Role</option>
                  <option value={'Agent'}>Agent</option>
                  <option value={'Frenchie'}>Frenchie</option>
                 
                </select>

              </label>
              {/* <label>
                Status
                <select
                  value={newAgent.isActive}
                  onChange={(e) => setNewAgent({ ...newAgent, isActive: e.target.value === 'true' })}
                >
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </select>

              </label> */}
              <button type="submit">Add Agent</button>
              <button type="button" onClick={() => setIsAdding(false)}>Cancel</button>
            </form>
          </div>
        </div>
      )}
      {isEditing && (
        <div className="popup-overlay">
          <div className="popup">
            <div className='d-flex justify-content-between'>
              <h2>Edit Agent</h2>
              <span role='button' onClick={() => setIsEditing(false)}>&#10007;</span>
            </div>
            <hr />
            <form onSubmit={(e) => { e.preventDefault(); handleSave(currentAgent); }}>
              <label>
                <input
                  type="text"
                  name="username"
                  value={currentAgent.username}
                  readOnly
                />
              </label>
              <h5>Change password</h5>
              <label>
                Password
                <input
                  name="password"
                  type="text"
                  onChange={(e) => {
                    const newPassword = e.target.value;
                    setCurrentAgent({ ...currentAgent, password: newPassword });
                    setShowConfirmPassword(newPassword !== '');
                    if (newPassword === '') {
                      setConfirmPassword('');
                    }
                  }}
                  required
                />
              </label>
              {/* Only show confirm password field if showConfirmPassword is true */}
              {showConfirmPassword && (
                <label>
                  Confirm Password
                  <input
                    name="confirmPassword"
                    type="text"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                  />
                </label>
              )}
              {passwordMismatch && <p style={{ color: 'red' }}>Passwords do not match!</p>}
              <button type="submit">Save</button>
              <button type="button" onClick={() => setIsEditing(false)}>Cancel</button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Agent
