import React, { useState, useEffect } from 'react';
import axios from 'axios';
import config from './Utils/config';
import showToast from './Utils/showToast';
import Loader from './Utils/loader';
import '../css/Dropdown.css';
import ConfirmationModal from './Utils/ConfirmationModal';
import { Grid } from '@mui/material';

const Dropdown = () => {


  const [dropdownData, setDropdownData] = useState([]);
  const [newItem, setNewItem] = useState({});
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editItem, setEditItem] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(5);
  const [totalItems, setTotalItems] = useState(0);

  const [PartyCategories, setPartyCategories] = useState({ categoryName: 'Select Category', categoryId: '' });
  const [PartyCategory, setPartyCategory] = useState('');

  const totalPages = Math.ceil(totalItems / pageSize);

  const currentItems = dropdownData.slice(
    (currentPage - 1) * pageSize,
    currentPage * pageSize
  );

  const startItem = (currentPage - 1) * pageSize + 1;
  const endItem = Math.min(currentPage * pageSize, totalItems);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };


  const categories = [
    'Add ons', 'Bounces', 'Characters', 'Event Types', 'Payment Status', 'Package Info Category', 'Party Packages',
    'Price', 'Tax', 'Venues'
  ];

  const categoryApiUrlMapping = {
    'Add ons': 'Addons',
    'Venues': 'Venues',
    'Event Types': 'EventTypes',
    'Party Packages': 'PartyPackages',
    'Characters': 'Characters',
    'Bounces': 'Bounces',
    'Payment Status': 'PaymentStatus',
    'Package Info Category': 'Categories',
    'Price': 'Price',
    'Tax': 'Tax',
  };

  const categoryDataMapping = {
    'Add ons': 'addon',
    'Venues': 'venue',
    'Event Types': 'eventType',
    'Party Packages': 'partyPackage',
    'Characters': 'character',
    'Bounces': 'bounce',
    'Payment Status': 'paymentStatus',
    'Package Info Category': 'category',
    'Price': 'price',
    'Tax': 'tax',
  };

  const [selectedCategory, setSelectedCategory] = useState(categories.length > 0 ? categories[0] : '');

  // Fetch data for the selected category
  const fetchDropdownData = async (category) => {
    setLoading(true);

    try {
      const token = localStorage.getItem('token');
      const url = `${config.apiBaseUrl}${categoryApiUrlMapping[category]}`;
      const response = await axios.get(url, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      setDropdownData(response.data);
      setTotalItems(response.data.length);

      if (category === 'Party Packages') {
        const url = `${config.apiBaseUrl}Categories`;
        const response = await axios.get(url, {
          headers: {
            'Authorization': `Bearer ${token}`,
          },
        });
        setPartyCategories(response.data);
      }

    } catch (error) {
      showToast({
        type: 'error',
        message: `Error fetching ${category} data`,
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCategory) {
      fetchDropdownData(selectedCategory);
      setNewItem({
        [`${categoryDataMapping[selectedCategory]}Name`]: '',
        price: '',
        // branchId: '',
        // companyId: '',
        categoryId: '',
        tax: ''
      });
    }
  }, [selectedCategory]);

  // Handle new item input change
  const handleNewItemChange = (e) => {
    const { name, value } = e.target;
    setNewItem({
      ...newItem,
      [name]: value,
    });
  };

  // Handle adding new item to the selected category
  const handleAddNewItem = async () => {
    const itemName = newItem[`${categoryDataMapping[selectedCategory]}Name`];
    if (!itemName || !newItem.categoryId) {
      showToast({
        type: 'error',
        message: 'Name and Category are required.',
      });
      return;
    }

    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const url = `${config.apiBaseUrl}${categoryApiUrlMapping[selectedCategory]}`;
      await axios.post(url, newItem, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      showToast({
        type: 'success',
        message: `${selectedCategory} item added successfully!`,
      });
      fetchDropdownData(selectedCategory);
      resetForm();
    } catch (error) {
      showToast({
        type: 'error',
        message: `Error adding new ${selectedCategory} item.`,
      });
    } finally {
      setLoading(false);
    }
  };


  // Handle edit button click
  const handleEditItem = (item) => {
    setIsEditing(true);
    setEditItem(item);
    setNewItem({
      [`${categoryDataMapping[selectedCategory]}Name`]: item[`${categoryDataMapping[selectedCategory]}Name`],
      price: item.price || '',
      // branchId: item.branchId || '',
      // companyId: item.companyId || '',
      categoryId: item.categoryId || '',
      tax: item.tax || ''
    });
    setShowModal(true);
  };

  // Handle updating the edited item
  const handleUpdateItem = async () => {
    const itemName = newItem[`${categoryDataMapping[selectedCategory]}Name`];
    if (!itemName) {
      showToast({
        type: 'error',
        message: 'Name is required.',
      });
      return;
    }

    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const url = `${config.apiBaseUrl}${categoryApiUrlMapping[selectedCategory]}/${editItem[`${categoryDataMapping[selectedCategory]}Id`]}`; // Include ID in URL for PUT request

      const payload = {
        [`${categoryDataMapping[selectedCategory]}Id`]: editItem[`${categoryDataMapping[selectedCategory]}Id`],
        [`${categoryDataMapping[selectedCategory]}Name`]: newItem[`${categoryDataMapping[selectedCategory]}Name`],
        price: newItem.price || '',
        // branchId: newItem.branchId || '',
        // companyId: newItem.companyId || '',
        categoryId: newItem.categoryId || '',
        tax: newItem.tax || ''
      };

      await axios.put(url, payload, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      showToast({
        type: 'success',
        message: `${selectedCategory} item updated successfully!`,
      });

      fetchDropdownData(selectedCategory);
      resetForm();
    } catch (error) {
      showToast({
        type: 'error',
        message: `Error updating ${selectedCategory} item.`,
      });
    } finally {
      setLoading(false);
    }
  };


  // Handle delete item
  const handleDeleteItem = (itemId) => {
    setItemToDelete(itemId);
    setIsDeleteModalOpen(true);
  };

  const confirmDeleteItem = async () => {
    if (!itemToDelete) return;

    setLoading(true);
    try {
      const token = localStorage.getItem('token');
      const url = `${config.apiBaseUrl}${categoryApiUrlMapping[selectedCategory]}/${itemToDelete}`;
      await axios.delete(url, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });
      showToast({
        type: 'success',
        message: `${selectedCategory} item deleted successfully!`,
      });
      fetchDropdownData(selectedCategory);
    } catch (error) {
      showToast({
        type: 'error',
        message: `Error deleting ${selectedCategory} item.`,
      });
    } finally {
      setLoading(false);
      setIsDeleteModalOpen(false);
      setItemToDelete(null);
    }
  };

  // Reset the form fields
  const resetForm = () => {
    setNewItem({
      [`${categoryDataMapping[selectedCategory]}Name`]: '',
      price: '',
      // branchId: '',
      // companyId: '',
      categoryId: ''
    });
    setIsEditing(false);
    setShowModal(false);
    setEditItem(null);
  };

  return (
    <div className="dropdown-page">
      <div className="form-container">
        <Grid container spacing={2} className="form-grid">
          <Grid container item xs={12} md={6}>
            <Grid item xs={12} md={3}>
              <label>Select Category: </label>
            </Grid>
            <Grid item xs={12} md={5}>
              <select
                name='category'
                value={selectedCategory}
                onChange={(e) => setSelectedCategory(e.target.value)}
              >
                <option value="">--Select--</option>
                {categories.map((category, index) => (
                  <option key={index} value={category}>{category}</option>
                ))}
              </select>
            </Grid>
          </Grid>

          <Grid item xs={12} md={6}>
            {/* Button to open modal */}
            {selectedCategory && (
              <div className="mt-3 d-flex justify-content-end">
                <button onClick={() => setShowModal(true)}>
                  Add {selectedCategory} Item
                </button>
              </div>
            )}
          </Grid>

        </Grid>


      </div>



      {/* Modal */}
      {showModal && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className='d-flex justify-content-between'>
              <h3>{isEditing ? `Edit ${selectedCategory}` : `Add ${selectedCategory}`}</h3>
              <span role="button" onClick={resetForm}>
                &#10007;
              </span>
            </div>
            {['Party Packages'].includes(selectedCategory) && (
              <>
                <label>Category: </label>
                <select
                  value={PartyCategory}
                  onChange={(e) => {
                    setPartyCategory(e.target.value);
                    setNewItem((prevState) => ({
                      ...prevState,
                      categoryId: e.target.value, // Update categoryId here
                    }));
                  }}
                >
                  <option value="">--Select--</option>
                  {PartyCategories.map((category, index) => (
                    <option key={index} value={category.categoryId}>{category.categoryName}</option>
                  ))}
                </select>

              </>
            )}

            <label>Name</label>
            <input
              className='inputField'
              type="text"
              name={`${categoryDataMapping[selectedCategory]}Name`}
              value={newItem[`${categoryDataMapping[selectedCategory]}Name`] || ''}
              placeholder={`Enter ${selectedCategory} Name`}
              onChange={handleNewItemChange}
            />

            {['Price', 'Add ons', 'Characters', 'Bounces', 'Party Packages'].includes(selectedCategory) && (
              <>
                <label>Price</label>
                <input
                  className='inputField'
                  type="number"
                  name="price"
                  value={newItem.price || ''}
                  onChange={handleNewItemChange}
                />
              </>
            )}

            {['Party Packages'].includes(selectedCategory) && (
              <>
                <label>Tax</label>
                <input
                  className='inputField'
                  type="number"
                  name="tax"
                  value={newItem.tax || ''}
                  onChange={handleNewItemChange}
                />
              </>
            )}

            {/* <label>Branch ID</label>
            <input
              className='inputField'
              type="text"
              name="branchId"
              value={newItem.branchId || ''}
              onChange={handleNewItemChange
            />

            <label>Company ID</label>
            <input
              className='inputField'
              type="text"
              name="companyId"
              value={newItem.companyId || ''}
              onChange={handleNewItemChange}
            /> */}

            <button onClick={isEditing ? handleUpdateItem : handleAddNewItem}>
              {isEditing ? 'Update Item' : 'Add New Item'}
            </button>
          </div>
        </div>
      )}

      {/* Display loading spinner */}
      <Loader isLoading={loading} />

      {/* Display the list of items for the selected category */}
      {selectedCategory && (
        <div>

          <table>
            <thead>
              <tr>
                <th>ID</th>
                <th>Name</th>
                {['Price', 'Add ons', 'Characters', 'Bounces', 'Party Packages'].includes(selectedCategory) && <th>Price</th>}
                {/* <th>Branch ID</th>
                <th>Company ID</th> */}
                {['Party Packages'].includes(selectedCategory) && <th>Tax</th>}

                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {currentItems.map(item => (
                <tr key={item[`${categoryDataMapping[selectedCategory]}Id`]}>
                  <td>{item[`${categoryDataMapping[selectedCategory]}Id`]}</td>
                  <td>{item[`${categoryDataMapping[selectedCategory]}Name`]}</td>
                  {['Price', 'Add ons', 'Characters', 'Bounces', 'Party Packages'].includes(selectedCategory) && (
                    <td>{item.price || '-'}</td>
                  )}
                  {['Party Packages'].includes(selectedCategory) && (
                    <td>{item.tax || '-'}</td>
                  )}
                  {/* <td>{item.branchId}</td>
                  <td>{item.companyId}</td> */}
                  <td>
                    <button
                      className="action-button edit-button"
                      onClick={() => handleEditItem(item)}
                      title="Edit"
                    >
                      <i className="fa fa-edit"></i>
                    </button>
                    <button
                      className="action-button delete-button"
                      onClick={() => handleDeleteItem(item[`${categoryDataMapping[selectedCategory]}Id`])}
                      title="Delete"
                    >
                      <i className="fa fa-trash"></i>
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>

          {/* Pagination Info */}
          <div className="pagination-info">
            <span>
              Displaying {startItem}-{endItem} of {totalItems} items
            </span>
          </div>

          {/* Pagination Controls */}
          <div className="pagination-controls">
            <button onClick={handlePrevPage} disabled={currentPage === 1} aria-label="Previous">
              <i className="fa fa-chevron-left"></i>
            </button>

            {Array.from({ length: totalPages }, (_, index) => (
              <button
                key={index + 1}
                onClick={() => handlePageChange(index + 1)}
                className={currentPage === index + 1 ? 'active' : ''}
                aria-label={`Page ${index + 1}`}
              >
                {index + 1}
              </button>
            ))}

            <button onClick={handleNextPage} disabled={currentPage === totalPages} aria-label="Next">
              <i className="fa fa-chevron-right"></i>
            </button>
          </div>
        </div>
      )}

      <ConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={confirmDeleteItem}
        message={`Are you sure you want to delete this ${selectedCategory} item?`}
      />
    </div>
  );
};

export default Dropdown;
