import React, { useState, useCallback, useEffect } from "react";
import {
  Grid,
  Box,
  Avatar,
  Typography,
  Button,
  Paper,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
} from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import config from "./Utils/config";
import { keyboard } from "@testing-library/user-event/dist/keyboard";

const Profile = () => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [open, setOpen] = useState(false);
  const [data, setData] = useState([""]);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newConfirmPassword, setNewConfirmPassword] = useState("");
  const [showPassword, setShowPassword] = useState({
    current: false,
    new: false,
    confirm: false,
  });

  useEffect(() => {
    const token = localStorage.getItem("token");

    // Fetch teams
    const fetchTeams = async () => {
      setLoading(true);
      setError(null); // Reset error state before making a new request

      try {
        const response = await fetch(
          `${config.apiBaseUrl}Users/getCurrentUser-Details`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Include the token in the header
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setData(data); // Set fetched teams data
      } catch (error) {
        setError("Error fetching teams: " + error.message); // Set error message if something goes wrong
      } finally {
        setLoading(false); // Set loading to false once the fetch completes
      }
    };

    fetchTeams();
  }, []);

  // Handle password change submit
  const handlePasswordChange = async () => {
    if (newPassword !== newConfirmPassword) {
      alert("New password and confirm password do not match.");
      return;
    }

    const token = localStorage.getItem("token");

    // Make POST request to change password
    try {
      const response = await fetch(
        `${config.apiBaseUrl}Users/Change-Password`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({
            OldPassword: currentPassword,
            newPassword: newPassword,
          }),
        }
      );

      if (response.ok) {
        alert("Password changed successfully.");
        setOpen(false); // Close the dialog after successful password change
      } else {
        const errorData = await response.json();
        alert("Error: " + errorData.message || "Password change failed.");
      }
    } catch (error) {
      alert("Error: " + error.message);
    }
  };

  console.log(data);

  const handleFileChange = useCallback((event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(URL.createObjectURL(file));
    }
  }, []);

  const handleClickOpen = () => {
    setOpen(true);
    setCurrentPassword("");
    setNewPassword("");
    setNewConfirmPassword("");
  };

  const handleClose = () => setOpen(false);

  const handleTogglePassword = (field) => {
    setShowPassword((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  // Styles
  const avatarStyle = {
    width: 120,
    height: 120,
    marginBottom: 2,
    margin: "0 auto",
  };

  const iconButtonStyle = {
    position: "relative",
    top: -30,
    left: "8%",
    transform: "translateX(-50%)",
    backgroundColor: "white",
    borderRadius: "50%",
    padding: 0.5,
    boxShadow: 2,
  };

  return (
    <Box sx={{ flexGrow: 1, padding: 3, margin: "100px auto" }}>
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        {/* Profile Info Section */}
        <Grid item xs={12} md={4}>
          <Paper
            sx={{
              padding: 3,
              textAlign: "center",
              boxShadow:
                "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px",
            }}
          >
            <Avatar
              src={image || "https://via.placeholder.com/120"}
              alt="Profile Picture"
              sx={avatarStyle}
            />

            {/* Camera Icon below the Avatar */}
            <IconButton sx={iconButtonStyle} component="label">
              <CameraAltIcon sx={{ color: "#1976d2" }} />
              <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                hidden
              />
            </IconButton>

            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", fontSize: "1.5rem" }}
            >
              {data.userName}
            </Typography>

            <Typography
              variant="body2"
              sx={{ color: "text.secondary", marginTop: 2 }}
            >
              <b>Email</b>: {data.email}
            </Typography>
            <Typography
              variant="body2"
              sx={{ color: "text.secondary", marginTop: 2 }}
            >
              <b>Branch Name</b>: {data.branchName}
            </Typography>

            {/* Change Password Button */}
            <Button
              variant="contained"
              color="primary"
              sx={{ marginTop: 2, marginRight: 2 }}
              onClick={handleClickOpen}
            >
              Change Password
            </Button>
          </Paper>
        </Grid>
      </Grid>

      {/* Modal Dialog for Change Password */}
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Change Password</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="current-password"
            label="Enter your Current Password"
            type={showPassword.current ? "text" : "password"}
            fullWidth
            value={currentPassword}
            onChange={(e) => setCurrentPassword(e.target.value)}
            sx={{ marginBottom: 2 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleTogglePassword("current")}
                    edge="end"
                  >
                    {showPassword.current ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="dense"
            id="new-password"
            label="Enter your New Password"
            type={showPassword.new ? "text" : "password"}
            fullWidth
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            sx={{ marginBottom: 2 }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleTogglePassword("new")}
                    edge="end"
                  >
                    {showPassword.new ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            margin="dense"
            id="new-confirm-password"
            label="Confirm Your New Password"
            type={showPassword.confirm ? "text" : "password"}
            fullWidth
            value={newConfirmPassword}
            onChange={(e) => setNewConfirmPassword(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => handleTogglePassword("confirm")}
                    edge="end"
                  >
                    {showPassword.confirm ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button onClick={handlePasswordChange} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Profile;
