
// Geocode address to lat/lng

import { loadGoogleMapsAPI } from "./LoadGoogleMap";


export const geocodeAddress = async (address) => {
  try {
    // Ensure the Google Maps API is loaded
    await loadGoogleMapsAPI();

    if (!window.google) return Promise.reject("Google Maps API is not loaded yet");

    const geocoder = new window.google.maps.Geocoder();
    return new Promise((resolve, reject) => {
      geocoder.geocode({ address }, (results, status) => {
        if (status === "OK") {
          const location = results[0].geometry.location;
          resolve({ lat: location.lat(), lng: location.lng() });
        } else {
          reject(`Geocoding failed for address: ${address}`);
        }
      });
    });
  } catch (error) {
    return Promise.reject("Failed to load Google Maps API: " + error.message);
  }
};



export const calculateDistancesAndDurations = async (locations, setDistances, setDurations) => {
  try {
    // Ensure the Google Maps API is loaded
    await loadGoogleMapsAPI();

    if (window.google && window.google.maps && window.google.maps.geometry && locations.length > 1) {
      const newDistances = [0];
      const newDurations = [0];
      const directionsService = new window.google.maps.DirectionsService();

      const calculateDuration = (origin, destination) => {
        return new Promise((resolve, reject) => {
          directionsService.route(
            { origin, destination, travelMode: window.google.maps.TravelMode.DRIVING },
            (result, status) => {
              if (status === window.google.maps.DirectionsStatus.OK) {
                const duration = result.routes[0].legs[0].duration.value;
                resolve((duration / 60).toFixed(0)); // Convert to minutes
              } else {
                reject(`Directions request failed due to ${status}`);
              }
            }
          );
        });
      };

      const calculateAllDurationsAndDistances = async () => {
        for (let i = 1; i < locations.length; i++) {
          const origin = new window.google.maps.LatLng(locations[i - 1].lat, locations[i - 1].lng);
          const destination = new window.google.maps.LatLng(locations[i].lat, locations[i].lng);

          try {
            const distance = window.google.maps.geometry.spherical.computeDistanceBetween(origin, destination) / 1000;
            newDistances.push(distance.toFixed(1)); // Convert meters to kilometers
            const duration = await calculateDuration(origin, destination);
            newDurations.push(duration);
          } catch (error) {
            console.error(error);
            newDurations.push(0);
          }
        }

        setDistances(newDistances);
        setDurations(newDurations);
      };

      calculateAllDurationsAndDistances();
    } else {
      console.error("Google Maps Geometry library or Maps API is not available.");
    }
  } catch (error) {
    console.error("Failed to load Google Maps API:", error.message);
  }
};