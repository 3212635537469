import axios from "axios";
import { useEffect, useState } from "react";
import config from "./Utils/config";
import Loader from "./Utils/loader";
import '../css/ManageCompany.css';
import showToast from "./Utils/showToast";
import ConfirmationModal from "./Utils/ConfirmationModal";
import convertISOStringToMonthDay from "./Utils/CustomFormatter";
import BranchFilters from "./Utils/BranchFilters";

const ManageBranches = () => {
    const [searchData, setSearchData] = useState({
        branchName: '',
        email: '',
        phone: '',
        createdDateFrom: '',
        createdDateTo: '',
        contactPerson: '',
    });
    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentBranches, setCurrentBranches] = useState(null);
    const [isAddModalOpen, setIsAddModalOpen] = useState(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [branchesDelete, setBranchesDelete] = useState(null);
    const [branches, setBranches] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [branchesForm, setBranchesForm] = useState({
        branchName: '',
        email: '',
        phone: '',
        contactPerson: '',
    });
    const [originalBranches, setOriginalBranches] = useState([]);
    useEffect(() => {
        const fetchBranchesList = async () => {
            try {
                const apiUrl = `${config.apiBaseUrl}Branches/BranchList`;
                const token = localStorage.getItem('token');
                const response = await axios.get(apiUrl, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                setBranches(response.data);
                setOriginalBranches(response.data); // Store original branches for search
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setError(error);
                showToast({ type: 'error', message: 'Error fetching branches!' });
            }
        };

        fetchBranchesList();
    }, []);

    if (loading) {
        return <Loader isLoading={loading} />;
    }

    if (error) {
        return <div>Error fetching data: {error.message}</div>;
    }

    const handleEdit = (branch) => {
        setCurrentBranches(branch);
        setBranchesForm({
            branchName: branch.branchName,
            email: branch.email,
            phone: branch.phone,
            contactPerson: branch.contactPerson,
        });
        setIsEditing(true);
        setIsAddModalOpen(true);
    };

    const handleAdd = () => {
        setBranchesForm({
            branchName: '',
            email: '',
            phone: '',
            contactPerson: '',
        });
        setIsAdding(true);
        setIsAddModalOpen(true);
    };
    const handleSearchDataChange = (search) => {
        const hasSearchValues = Object.values(search).some(value => value !== "");

        if (!hasSearchValues) {
            setBranches(originalBranches); // Reset to original branches if no search values
            return;
        }
        console.log(search);
        setBranches(() => {
            return originalBranches.filter(branch => {
                console.log(branch);
                return (
                    (search.branchName === "" || branch.branchName.startsWith(search.branchName)) &&
                    (search.email === "" || branch.email.startsWith(search.email)) &&
                    (search.phone === "" || branch.phone.startsWith(search.phone)) &&
                    (search.contactPerson === "" || branch.contactPerson.startsWith(search.contactPerson)) &&
                    (search.createdDateFrom === "" || new Date(branch.createdDate) >= new Date(search.createdDateFrom)) &&
                    (search.createdDateTo === "" || new Date(branch.createdDate) <= new Date(search.createdDateTo))
                );
            });
        });
    };
    const handleChange = (e) => {
        const { name, value } = e.target;
        setBranchesForm({
            ...branchesForm,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        try {
            if (isAdding) {
                // Add branches
                const response = await axios.post(`${config.apiBaseUrl}Branches/AddBranch`, branchesForm, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                setBranches((prevBranches) => [...prevBranches, response.data]);
                showToast({ type: 'success', message: 'Branch added successfully!' });
            } else if (isEditing) {
                // Edit branches
                const id = currentBranches.branchId;
                const response = await axios.put(`${config.apiBaseUrl}Branches/BranchUpdate/${id}`, {
                    ...branchesForm,
                    branchId: currentBranches.branchId,
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                showToast({ type: 'success', message: 'Branch updated successfully!' });
                // setBranches((prevBranches) => 
                //     prevBranches.map((branch) => branch.branchId === currentBranches.branchId ? response.data : branch)
                // );
                window.location.reload();

            }
        } catch (error) {
            showToast({ type: 'error', message: 'Error submitting data!' });
        } finally {
            setIsAdding(false);
            setIsEditing(false);
            setBranchesForm({
                branchName: '',
                email: '',
                phone: '',
                contactPerson: '',
            });
            setIsAddModalOpen(false);
        }
    };

    const handleDeleteConfirmation = (branch) => {
        setBranchesDelete(branch.branchId);
        setDeleteMessage(`Are you sure you want to delete "${branch.branchName}"?`);
        setIsDeleteModalOpen(true);
    };

    const deleteBranch = async () => {
        try {
            const token = localStorage.getItem('token');
            const statusUrl = `${config.apiBaseUrl}Branches/${branchesDelete}`;
            await axios.delete(statusUrl, {
                headers: {
                    'Authorization': `Bearer ${token}`,
                },
            });

            setBranches(prevBranches => prevBranches.filter(branch => branch.branchId !== branchesDelete));
            showToast({ type: 'success', message: 'Branch deleted successfully!' });
        } catch (error) {
            showToast({ type: 'error', message: 'Error deleting branch!' });
        } finally {
            setIsDeleteModalOpen(false);
            setBranchesDelete(null);
            setDeleteMessage('');
        }
    };
    return (
        <div className="company-table">
            <div className="d-flex justify-content-between">
                <h2>Branches List</h2>
                <button className="btn btn-primary" onClick={handleAdd}>+ Add</button>
            </div>

            <BranchFilters onSearchDataChange={handleSearchDataChange} />
           
            {/* Add/Edit Form in Modal */}
            {isAddModalOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h3>{isAdding ? 'Add New Branch' : 'Edit Branch'}</h3>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <label>Branch Name</label>
                                <input
                                    type="text"
                                    name="branchName"
                                    value={branchesForm.branchName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div>
                                <label>Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={branchesForm.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div>
                                <label>Phone</label>
                                <input
                                    type="text"
                                    name="phone"
                                    value={branchesForm.phone}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <label>Contact Person</label>
                                <input
                                    type="text"
                                    name="contactPerson"
                                    value={branchesForm.contactPerson}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary">{isAdding ? 'Add' : 'Update'}</button>
                                <button type="button" className="btn btn-secondary" onClick={() => setIsAddModalOpen(false)}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}


            <ConfirmationModal
                isOpen={isDeleteModalOpen}
                onClose={() => setIsDeleteModalOpen(false)}
                onConfirm={deleteBranch}
                message={deleteMessage}
            />


            <table border="1" cellPadding="10" cellSpacing="0">
                <thead>
                    <tr>
                        <th>Branch Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Contact Person</th>
                        <th>Created On</th>
                        <th>Last Modified</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {branches.map((branch) => (
                        <tr key={branch.branchId}>
                            <td>{branch.branchName}</td>
                            <td>{branch.email}</td>
                            <td>{branch.phone}</td>
                            <td>{branch.contactPerson}</td>
                            <td>{convertISOStringToMonthDay(branch.createdDate)}</td>
                            <td>{convertISOStringToMonthDay(branch.lastModifiedDate)}</td>
                            <td>
                                <button className="action-button edit-button" onClick={() => handleEdit(branch)} title="Edit branch">
                                    <i className="fa fa-edit"></i>
                                </button>
                                <button className="action-button delete-button" onClick={() => handleDeleteConfirmation(branch)} title="Delete branch">
                                    <i className="fa fa-trash"></i>
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ManageBranches;
