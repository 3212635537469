// loadGoogleMapsAPI.js

import config from "./config";

export const loadGoogleMapsAPI = () => {
    return new Promise((resolve, reject) => {
      // If Google Maps is already loaded, resolve immediately
      if (window.google && window.google.maps) {
        return resolve(window.google.maps); // Return early if already loaded
      }
  
      // Create a script element to load the Google Maps API
      const script = document.createElement("script");
      script.src = `https://maps.googleapis.com/maps/api/js?key=${config.googleApiKey}&loading=async&libraries=places,marker,geometry&v=3.45`; // Replace with your actual API key
      script.async = true;
      script.defer = true;
  
      // On script load success, resolve the promise
      script.onload = () => resolve("Google Maps API loaded successfully.");
  
      // On script load failure, reject the promise
      script.onerror = () => reject("Failed to load Google Maps API");
  
      // Append the script to the document head
      document.head.appendChild(script);
  
      // Clean up by removing the script when the component is unmounted (if using in a React component)
      return () => document.head.removeChild(script);
    });
  };
  