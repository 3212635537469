import React, { useContext, useState, useEffect } from "react";
import { ApiDataContext } from "./ApiDataContext";
import { Bar } from "react-chartjs-2";

function TodayBarChart() {
  const { barToday, loading, error } = useContext(ApiDataContext);

  const [chartData, setChartData] = useState({
    labels: [], // Team IDs as labels
    datasets: [
      {
        label: "Calls by Team", // Chart label
        data: [], // Call count for each team
        backgroundColor: "rgba(75, 132, 192, 1)", // Bar color
        borderColor: "rgba(75, 132, 192, 1)", // Border color
        borderWidth: 1, // Border width
      },
    ],
  });

  useEffect(() => {
    if (loading) return; // Avoid running the code if loading is still true

    if (error) {
      // Handle error state (e.g., show an error message)
      console.error("Error fetching data:", error);
      return;
    }

    const processData = () => {
      const today = new Date().toISOString().split("T")[0]; // Get today's date in YYYY-MM-DD format
      //   const today="2024-12-02";
      const todaysData = barToday.filter((entry) => {
        if (!entry.date || isNaN(new Date(entry.date))) {
          console.warn(
            `Invalid date value for entry: ${JSON.stringify(entry)}`
          );
          return false; // Skip invalid dates
        }

        const entryDate = entry.date; // Extract entry date
        return entryDate === today; // Compare entry date with today's date
      });

      const teamCallCount = {};

      todaysData.forEach((entry) => {
        const { teamId } = entry;
        if (teamCallCount[teamId]) {
          teamCallCount[teamId] += 1; // Increment call count for this team
        } else {
          teamCallCount[teamId] = 1; // Initialize call count for this team
        }
      });

      const teams = Object.keys(teamCallCount).map(
        (teamId) => `Team No ${teamId}`
      );
      const callCounts = Object.values(teamCallCount);

      setChartData({
        labels: teams,
        datasets: [
          {
            label: "Total Calls by Team",
            data: callCounts,
            backgroundColor: "rgba(75, 132, 192, 1)",
            borderColor: "rgba(75, 132, 192, 1)",
            borderWidth: 1,
          },
        ],
      });
    };

    if (barToday.length > 0) {
      processData();
    }
  }, [barToday, loading, error]); // Make sure to react to barToday, loading, and error

  return (
    <>
      <h3>Today Call Volume by Team</h3>
      {loading ? (
        <p>Loading...</p>
      ) : error ? (
        <p>{error}</p>
      ) : (
        <Bar data={chartData} />
      )}
    </>
  );
}

export default TodayBarChart;
