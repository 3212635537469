import React, { useEffect } from "react";
import config from "./Utils/config";
import { Bar } from "react-chartjs-2";

const TeamGraph = () => {
    const [filteredData, setFilteredData] = React.useState([]);
    const [chartData, setChartData] = React.useState(null); 
    const token = localStorage.getItem('token');

    useEffect(() => {
        fetchDataFromAPI();
    }, []);

    // Fetch data from API
    const fetchDataFromAPI = async () => {
        try {
            const response = await fetch(config.apiBaseUrl + 'ContractTimeTeamInfoes', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                },
            });
            const data = await response.json();
            filterDataByDate(data);
        } catch (error) {
            console.error("Error fetching data from API:", error);
        }
    };

    // Filter data based on entryDate and group by day
    const filterDataByDate = (data) => {
        const today = new Date();
        const lastWeek = new Date(today);
        lastWeek.setDate(today.getDate() - 7);

        const filtered = data.filter(item => {
            const entryDate = new Date(item.entryDate);
            return entryDate >= lastWeek && entryDate <= today;
        });

        // Group contracts by day
        const dailyCounts = groupByDay(filtered);
        setFilteredData(dailyCounts);
        generateChartData(dailyCounts);
    };

    // Group contracts by day of the week
    const groupByDay = (data) => {
        const dayCounts = {
            Monday: 0,
            Tuesday: 0,
            Wednesday: 0,
            Thursday: 0,
            Friday: 0,
            Saturday: 0,
            Sunday: 0
        };

        data.forEach(item => {
            const entryDate = new Date(item.entryDate);
            const dayOfWeek = entryDate.toLocaleString('en-us', { weekday: 'long' });
            if (dayCounts[dayOfWeek] !== undefined) {
                dayCounts[dayOfWeek]++;
            }
        });

        return dayCounts;
    };

    // Generate the chart data
    const generateChartData = (dailyCounts) => {
        const labels = Object.keys(dailyCounts);
        const data = Object.values(dailyCounts);

        setChartData({
            labels: labels,
            datasets: [
                {
                    label: 'Teams Per Day',
                    data: data,
                    backgroundColor: 'rgba(75, 192, 192, 0.6)',
                    borderColor: 'rgba(75, 192, 192, 1)',
                    borderWidth: 1,
                }
            ]
        });
    };

    const configs = {
        type: 'bar',
        options: {
            plugins: {
                title: {
                    display: true,
                    text: 'Team Information by Day',
                },
            },
            responsive: true,
            scales: {
                x: {
                    stacked: true,
                },
                y: {
                    stacked: true,
                    beginAtZero: true,
                }
            }
        }
    };

    return (
        <div>
            {/* Check if chartData is available before rendering Bar chart */}
            {chartData ? (
                <div className="dashboard-grid-item-one  mt-3">
                    <div className="component">
                        <h3>Team Information</h3>
                        <Bar data={chartData} options={configs.options} />
                    </div>
                </div>
            ) : (
                <div>Loading team data...</div>
            )}
        </div>
    );
};

export default TeamGraph;
