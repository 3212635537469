import axios from "axios";
import { useEffect, useState } from "react";
import config from "./Utils/config";
import Loader from "./Utils/loader";
import '../css/ManageCompany.css';
import showToast from "./Utils/showToast";

const ManageCompany = () => {
    const [isAdding, setIsAdding] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [currentCompany, setCurrentCompany] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [companyDelete, setCompanyDelete] = useState(null);
    const [companies, setCompanies] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [companyForm, setCompanyForm] = useState({
        companyName: '',
        email: '',
        phone: '',
        contactPerson: '',
    });

    useEffect(() => {
        const fetchCompanyList = async () => {
            try {
                const apiUrl = `${config.apiBaseUrl}Company/CompanyList`;
                const token = localStorage.getItem('token');
                const response = await axios.get(apiUrl, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                setCompanies(response.data);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                setError(error);
            }
        };

        fetchCompanyList();
    }, []);

    if (loading) {
        return <Loader isLoading={loading} />;
    }

    if (error) {
        return <div>Error fetching data: {error.message}</div>;
    }

    const handleEdit = (company) => {
        setCurrentCompany(company);
        setCompanyForm({
            companyName: company.companyName,
            email: company.email,
            phone: company.phone,
            contactPerson: company.contactPerson,
        });
        setIsEditing(true);
        setIsModalOpen(true);
    };

    const handleAdd = () => {
        setCompanyForm({
            companyName: '',
            email: '',
            phone: '',
            contactPerson: '',
        });
        setIsAdding(true);
        setIsModalOpen(true);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setCompanyForm({
            ...companyForm,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        try {
            // if (isAdding) {
            //     // Add company
            //     const response = await axios.post(`${config.apiBaseUrl}Company/AddCompany`, companyForm, {
            //         headers: {
            //             'Authorization': `Bearer ${token}`,
            //         },
            //     });
            //     setCompanies((prevCompanies) => [...prevCompanies, response.data]);
            //     showToast({ type: 'success', message: 'Company added successfully!' });
            // } else 
            if (isEditing) {
                // Edit company
                const id=currentCompany.companyId;
                const response = await axios.put(`${config.apiBaseUrl}Company/CompanyUpdate/${id}`, {
                    ...companyForm,
                    companyId: currentCompany.companyId,
                }, {
                    headers: {
                        'Authorization': `Bearer ${token}`,
                    },
                });
                // setCompanies((prevCompanies) =>
                //     prevCompanies.map((company) => company.companyId === currentCompany.companyId ? response.data : company)
                // );
                showToast({ type: 'success', message: 'Company updated successfully!' });
                window.location.reload();
                
            }
        } catch (error) {
            showToast({ type: 'error', message: 'Error in submitting data!' });
        } finally {
            setIsAdding(false);
            setIsEditing(false);
            setCompanyForm({
                companyName: '',
                email: '',
                phone: '',
                contactPerson: '',
            });
            setIsModalOpen(false);
        }
    };

    // const handleDeleteConfirmation = (company) => {
    //     setCompanyDelete(company.companyId);
    //     setDeleteMessage(`Are you sure you want to delete "${company.companyName}"?`);
    //     setIsModalOpen(true);
    // };

    // const deleteCompany = async () => {
    //     try {
    //         const token = localStorage.getItem('token');
    //         const statusUrl = `${config.apiBaseUrl}Company/DeleteCompany/${companyDelete}`;
    //         await axios.delete(statusUrl, {
    //             headers: {
    //                 'Authorization': `Bearer ${token}`,
    //             },
    //         });

    //         setCompanies(prevCompanies => prevCompanies.filter(com => com.companyId !== companyDelete));

    //         showToast({
    //             type: 'success',
    //             message: "Deleted successfully!",
    //         });
    //     } catch (error) {
    //         showToast({
    //             type: 'error',
    //             message: "Error in deleting company!",
    //         });
    //     } finally {
    //         setIsModalOpen(false);
    //         setCompanyDelete(null);
    //         setDeleteMessage('');
    //     }
    // };
    const convertISOStringToMonthDay = (date) => {
        const tempDate = new Date(date).toString().split(' ');
        const formattedDate = `${tempDate[1]} ${+tempDate[2]}, ${+tempDate[3]}`;
        return formattedDate;
      };
    return (
        <div className="company-table">
            <div className="d-flex justify-content-between">
                <h2>Company List</h2>
                {/* <button className="btn btn-primary" onClick={handleAdd}>+ Add</button> */}
            </div>

            {/* Add/Edit Form in Modal */}
            {isModalOpen && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h3>{isAdding ? 'Add New Company' : 'Edit Company'}</h3>
                        <form onSubmit={handleSubmit}>
                            <div>
                                <label>Company Name</label>
                                <input
                                    type="text"
                                    name="companyName"
                                    value={companyForm.companyName}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div>
                                <label>Email</label>
                                <input
                                    type="email"
                                    name="email"
                                    value={companyForm.email}
                                    onChange={handleChange}
                                    required
                                />
                            </div>
                            <div>
                                <label>Phone</label>
                                <input
                                    type="text"
                                    name="phone"
                                    value={companyForm.phone}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <label>Contact Person</label>
                                <input
                                    type="text"
                                    name="contactPerson"
                                    value={companyForm.contactPerson}
                                    onChange={handleChange}
                                />
                            </div>
                            <div>
                                <button type="submit" className="btn btn-primary">{isAdding ? 'Add' : 'Update'}</button>
                                <button type="button" className="btn btn-secondary" onClick={() => setIsModalOpen(false)}>Cancel</button>
                            </div>
                        </form>
                    </div>
                </div>
            )}

            {/* Confirmation Modal for Delete */}
            {/* {companyDelete && (
                <div className="modal-overlay">
                    <div className="modal">
                        <h3>Delete Confirmation</h3>
                        <p>{deleteMessage}</p>
                        <button onClick={deleteCompany} className="btn btn-danger">Delete</button>
                        <button onClick={() => setIsModalOpen(false)} className="btn btn-secondary">Cancel</button>
                    </div>
                </div>
            )} */}

            <table border="1" cellPadding="10" cellSpacing="0">
                <thead>
                    <tr>
                        <th>Company Id</th>
                        <th>Company Name</th>
                        <th>Email</th>
                        <th>Phone</th>
                        <th>Contact Person</th>
                        <th>Created Date</th>
                        <th>Modified Date</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {companies.map((company, index) => (
                        <tr key={`${company.companyId}-${index}`}>
                            <td>{company.companyId}</td>
                            <td>{company.companyName}</td>
                            <td>{company.email}</td>
                            <td>{company.phone}</td>
                            <td>{company.contactPerson}</td>
                            <td>{convertISOStringToMonthDay(company.createdDate)}</td>
                            <td>{convertISOStringToMonthDay(company.lastModifiedDate)}</td>
                            <td>
                                <button className="action-button edit-button" onClick={() => handleEdit(company)} title="Edit company">
                                    <i className="fa fa-edit"></i>
                                </button>
                                {/* <button className="action-button delete-button" onClick={() => handleDeleteConfirmation(company)} title="Delete company">
                                    <i className="fa fa-trash"></i>
                                </button> */}
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default ManageCompany;
