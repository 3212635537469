import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; // Import useNavigate
import Box from "@mui/material/Box";
import IconButton from "@mui/material/IconButton";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import PersonIcon from "@mui/icons-material/Person";
import "./Login.css"; // Import the CSS file
import config from "./Utils/config";
import Loader from "./Utils/loader"; // Import Loader component

export default function Login() {
  const [showPassword, setShowPassword] = useState(false);
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate(); // Initialize useNavigate
  const [isLoading, setIsLoading] = useState(false); // Control loader visibility

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setIsLoading(true); // Show loader before login starts

    const apiUrl = config.apiBaseUrl + "Users/login-user";
    setEmailAddress(username);
    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ username, password }), // Send username and password
      });

      if (response.ok) {
        const data = await response.json();
        localStorage.setItem("token", data.token); // Store the token
        localStorage.setItem("role", data.role); // Store the role
        if (data.role)
          if (data.role === "Admin") {
            navigate("/dashboard-admin");
          } else if (data.role === "Agent") {
            navigate("/dashboard");
          } else if (data.role === "Frenchie") {
            navigate("/frenchie/dashboard");
          }
      } else {
        setError("Invalid username or password");
      }
    } catch (error) {
      setError("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false); // Hide loader after login completes
    }
  };

  const handleFocus = (e) => {
    e.target.value = "";
  };

  return (
    <div className="login-container">
      <Loader isLoading={isLoading} />
      <Box
        component="form"
        onSubmit={handleLogin}
        sx={{
          "& > :not(style)": { width: "100%" },
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          maxWidth: "400px",
          margin: "auto",
          padding: "1.5rem",
          backgroundColor: "#fff",
          borderRadius: "8px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
        noValidate
        autoComplete="off"
      >
        <TextField
          id="outlined-basic"
          label="Username"
          variant="outlined"
          sx={{
            margin: "1rem",
            width: "100%",
            backgroundColor: "none",
            "&:hover": {
              backgroundColor: "none",
            },
            "&.Mui-focused": {
              backgroundColor: "none",
            },
          }}
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment
                position="center"
                // sx={{ display: "flex", width: "65px" }}
              >
                <PersonIcon sx={{ paddingLeft: "5px", width: "40px" }} />
              </InputAdornment>
            ),
            sx: {
              "& input": {
                backgroundColor: "none",
                paddingLeft: "10px", // Add padding inside the text input field
              },
            },
          }}
        />
        <FormControl
          sx={{
            margin: "1rem",
            width: "100%",
            "&:hover": {
              backgroundColor: "none",
            },
            "&.Mui-focused": {
              backgroundColor: "none",
            },
          }}
          variant="outlined"
        >
          <InputLabel htmlFor="outlined-adornment-password">
            Password
          </InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? "text" : "password"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            onFocus={handleFocus} // Clear value on focus
            endAdornment={
              <InputAdornment
                position="end"
                sx={{ display: "flex", alignItems: "left", width: "45px" }}
              >
                <IconButton
                  aria-label={
                    showPassword ? "hide the password" : "display the password"
                  }
                  edge="end"
                  onClick={handleClickShowPassword}
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            sx={{
              "&:hover": {
                backgroundColor: "none",
              },
              "&.Mui-focused": {
                backgroundColor: "none",
              },
              "& input": {
                background: "none",
                paddingLeft: "10px", // Adjust padding for the text input inside the field
              },
            }}
            label="Password"
          />
        </FormControl>

        <Button
          type="submit"
          variant="contained"
          sx={{
            backgroundColor: "#009FD9",
            "&:hover": { backgroundColor: "#13b0d1" },
            padding: ".7rem",
            margin: ".7rem",
          }}
        >
          Login
        </Button>
        {error && <p className="error-message">{error}</p>}
      </Box>
    </div>
  );
}
