import React, { useState, useEffect } from "react";
import "../css/ViewAllTeams.css";
import { Button, TextField } from "@mui/material";
import config from "./Utils/config";
import { PrintFeature } from "./Utils/PrintFeature";
import {
  calculateDistancesAndDurations,
  geocodeAddress,
} from "./Utils/CalculateDistance";
import Loader from "./Utils/loader";
import showToast from "./Utils/showToast";

function ViewAllTeams() {
  const [teams, setTeams] = useState([]);
  const [loading, setLoading] = useState(true);
  const [visible, setVisible] = useState(false);
  const [error, setError] = useState(null);
  const [locations, setLocations] = useState([]);
  const [displayedData, setDisplayedData] = useState([]);
  const [distances, setDistances] = useState([0]);
  const [durations, setDurations] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    new Date().toISOString().split("T")[0]
  );

  useEffect(() => {
    const token = localStorage.getItem("token");

    // Fetch teams
    const fetchTeams = async () => {
      setLoading(true);
      setError(null); // Reset error state before making a new request

      try {
        const response = await fetch(
          `${config.apiBaseUrl}ContractTimeTeamInfoes/getContractTeamsData`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Include the token in the header
            },
          }
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        setTeams(data); // Set fetched teams data
      } catch (error) {
        setError("Error fetching teams: " + error.message); // Set error message if something goes wrong
      } finally {
        setLoading(false); // Set loading to false once the fetch completes
      }
    };

    fetchTeams();
  }, []);

  //update locations with coordinates
  useEffect(() => {
    const updateLocationsWithCoordinates = async () => {
      if (teams.length > 0) {
        setLoading(true);
        try {
          const updatedLocations = await Promise.all(
            { lastLatitute: 0, lastLongitude: 0 },
            teams.map(async (team) => {
              const fullAddress = `${team.address}, ${team.city}, ${team.zip}`;
              try {
                const coordinates = await geocodeAddress(fullAddress);
                return {
                  address: team.address,
                  zip: team.zip,
                  city: team.city,
                  lat: coordinates.lat,
                  lng: coordinates.lng,
                };
              } catch (error) {
                console.error(error);
                return null; // Skip failed geocoding
              }
            })
          );
          setLocations(
            updatedLocations.filter((location) => location !== null)
          );
        } catch (error) {
          console.error("Error geocoding addresses:", error);
        } finally {
          setLoading(false);
        }
      }
    };
    updateLocationsWithCoordinates();
  }, [teams]);

  // Group teams by teamNo and filter out unique teamNo
  const groupTeamsByTeamNo = (data) => {
    const groupedTeams = {};

    // Group teams by their teamNo
    data.forEach((team) => {
      if (!groupedTeams[team.teamNo]) {
        groupedTeams[team.teamNo] = [];
      }
      groupedTeams[team.teamNo].push(team);
    });

    return groupedTeams;
  };

  // Date Picker Function

  const handleDateChange = (e) => {
    const newDate = e.target.value;
    setSelectedDate(newDate);

    // If a date is selected, filter the data
    if (newDate) {
      const filtered = teams.filter((team) => team.entryDate === newDate);

      setDisplayedData(filtered);
    } else {
      setDisplayedData(teams);
    }
  };

  useEffect(() => {
    // Initially showing all data when the component is first rendered
    setDisplayedData(teams);
  }, [teams]);

  const handlePrint = () => {
    PrintFeature(displayedData, distances, durations);
    showToast({
      type: "success",
      message: "PDF generated successfully!",
    });
  };

  const handleDistance = async () => {
    try {
      await calculateDistancesAndDurations(
        locations,
        setDistances,
        setDurations
      );
    } catch (err) {
      console.error("Error calculating distances and durations:", err);
    }
  };

  if (loading) {
    return <Loader isLoading={loading} />;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  // Group the teams by teamNo
  const groupedTeams = groupTeamsByTeamNo(displayedData);

  return (
    <div className="all-team-container">
      <h1>All Teams</h1>

      <div className="all-team-upper">
        <TextField
          type="date"
          value={selectedDate}
          onChange={handleDateChange}
          InputLabelProps={{ shrink: true }}
          sx={{ mb: 2 }}
        />

        <Button
          onClick={handleDistance}
          variant="contained"
          sx={{ height: "100%" }}
          color="primary"
        >
          Show Distance
        </Button>

        <Button
          onClick={handlePrint}
          variant="contained"
          sx={{ height: "100%" }}
          color="primary"
        >
          Print
        </Button>
      </div>

      <div className="all-team-heading">
        {Object.keys(groupedTeams).length === 0 ? (
          <p>No teams available.</p>
        ) : (
          Object.keys(groupedTeams).map((teamNo, index) => (
            <div key={index} className="team-container">
              <div style={{ textAlign: "center", fontSize: "20px" }}>
                {teamNo}
              </div>
              <div className="all-team-data">
                {groupedTeams[teamNo].map((team, teamIndex) => (
                  <div className="all-team-box" key={teamIndex}>
                    <p>
                      {team.firstName} {team.lastName}
                    </p>
                    <p>{team.address}</p>
                    <p>{team.zip}</p>
                    <p>{team.city}</p>
                    <p>Distance : {distances[teamIndex]} km</p>
                    <p>Duration : {durations[teamIndex]} Min</p>
                    <p>{team.date}</p>
                    {/* You can add more team data here if needed */}
                  </div>
                ))}
              </div>
            </div>
          ))
        )}
      </div>
    </div>
  );
}

export default ViewAllTeams;
